import { ThemeColorMeta } from 'components/layouts/Page/types';

interface SeoTextTypes {
  title?: string;
  description?: string;
  themeColor?: ThemeColorMeta;
}

export const seoMain: SeoTextTypes = {
  title: 'A3 — сервис электронных платежей и эквайринга для бизнеса',
  description:
    'Платежный сервис А3 – это готовые бизнес-решения в области электронных платежей, с возможностью индивидуальной кастомизации под задачи бизнеса. Огромный опыт интеграции с банками и поставщиками услуг',
  themeColor: ThemeColorMeta.DarkBlue,
};

export const seoBanks: SeoTextTypes = {
  title: 'Сервис для банков по проведению платежей в пользу поставщиков услуг',
  description:
    'Платежный сервис А3 помогает банкам проводить оплату клиентов в пользу более 60 тыс. компаний, предоставляющих услуги ЖКХ, телекоммуникации, оплаты штрафов ГИБДД и государственных начислений',
  themeColor: ThemeColorMeta.LightPurple,
};

export const seoPaymentServices: SeoTextTypes = {
  title: 'Конструктор платежного сервиса А3 под задачи бизнеса',
  description:
    'Настраиваемая форма платежей для сайта или приложения, через которую клиенты могут оплатить любые услуги. Доступ к базе 60+ тыс. организаций и 122+ млн лицевых счетов с актуальными начислениями задолженностей',
  themeColor: ThemeColorMeta.LightLilac,
};

export const seoProviders: SeoTextTypes = {
  title: 'Подключение поставщиков услуг к приему платежей в топ-банках России',
  description:
    'Оперативное подключение поставщиков услуг, возможность отображать информацию о задолженностях и принимать платежи в крупнейших банковских приложениях и платежных сервисах',
  themeColor: ThemeColorMeta.LightMarine,
};

export const seoLKProviders: SeoTextTypes = {
  themeColor: ThemeColorMeta.LightMarine,
};

export const seoWork: SeoTextTypes = {
  title: 'Работа в компании А3',
  description:
    'А3 — продуктовая IT-компания, которая объединяет банки, поставщиков услуг и плательщиков. Предоставляет банкам и компаниям данные для платежных операций и проводит платежи онлайн',
  themeColor: ThemeColorMeta.LightBlue,
};

export const seoContacts: SeoTextTypes = {
  title: 'Контакты - Платежный сервис А3',
  description:
    'Связаться с нами можно по телефону - 8 (800) 100 39 00 с 9-18 каждый день. Бесплатно для звонков из России',
  themeColor: ThemeColorMeta.LightBlue,
};

export const seoEngine: SeoTextTypes = {
  title: 'О технологии платежного сервиса А3',
  description:
    'Уникальные технологии, опыт и удобный платежный сервис для всех участников процесса',
  themeColor: ThemeColorMeta.DarkBlue,
};
